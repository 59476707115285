<template>
  <div class="container">
    <div class="header">
      <div class="logo-container">
        <figure>
          <img src="@/assets/citi-white.svg" />
        </figure>
      </div>
    </div>
    <div class="padderr">
      <h2>
        Thank you!
      </h2>
      <h2>
        Your Video KYC call is already completed successfully.
      </h2>
    </div>
    <hr class="divider" />
    <div class="footer">
      <figure>
        <img src="@/assets/ct-logo.png" />
      </figure>
      <div class="f-text">
        <p>
          Citi.com is the global source of information about and
          <br />access to financial services provided by the Citigroup
          <br />family of companies.
          <br />Copyright &#169; 2020 Citigroup Inc.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    name: "",
    time: "",
    applicationId: ""
  }),
  methods: {}
};
</script>

<style scoped>

h2 {
  font-weight: normal;
}
.container {
  max-width: 100%;
  padding: 0px;
}
.check {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #056dae;
  color: #fff;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}
.check::before {
  position: absolute;
  left: 5px;
  top: 50%;
  height: 30%;
  width: 2px;
  background-color: #fff;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.check::after {
  position: absolute;
  left: 5px;
  bottom: 8px;
  height: 2px;
  width: 65%;
  background-color: #fff;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.header {
  opacity: 1;
  margin-bottom: 25px;
}
.header .top-bar {
  background-color: #333333;
  padding: 5px 10px;
  font-size: 12px;
  text-transform: uppercase;
}
.header .top-bar p {
  margin: 0;
  margin-right: auto;
  text-align: left;
  color: #fff;
}
.header .logo-container {
  background-image: linear-gradient(#4a9ad9, #1d4886);
  /* background: transparent linear-gradient(180deg, #00BEF7 0%, #00498A 100%) 0% 0% no-repeat padding-box; */
  height: 67px;
  padding: 5px 10px;
  position: relative;
}
.logo-container figure {
  width: 65px;
  overflow: hidden;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  margin: 0;
  text-align: left;
}
.logo-container figure img {
  width: 100%;
}
.text-container {
  color: #333333;
  margin-bottom: 125px;
  padding: 0 5px;
}
.text-container hr {
  width: 85%;
  border-width: 0;
  height: 1.5px;
  background-color: #cecece;
  color: grey;
  margin-bottom: 25px;
}
.text-container .top-cont {
  max-width: 300px;
  margin: 0 auto;
  font-size: 24px;
  line-height: 1.65;
}
.text-container .top-cont .app-id {
  font-size: 16px;
}
.text-container .mid-cont p {
  max-width: 240px;
  margin: 0 auto;
  line-height: 1.65;
}
.social-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.social-bar p:nth-child(1) {
  float: left;
  font-size: 12px;
  text-transform: uppercase;
}
.social-bar p {
  float: right;
  font-size: 12px;
  margin: 0;
  display: flex;
  align-items: center;
}
.social-bar p span img {
  margin-left: 5px;
  margin-right: 5px;
}
.divider {
  margin: 0;
  border-width: 0;
  height: 1.5px;
  background-color: #cecece;
  color: grey;
  margin-bottom: 25px;
}
.footer {
  display: inline-block;
  width: 100%;
  padding: 0 5px;
}
.footer figure {
  width: 25px;
  float: left;
  margin: 0;
}
.footer figure img {
  width: 100%;
}
.footer .f-text {
  float: right;
  font-size: 12px;
}
.footer .f-text p {
  text-align: right;
  color: #9a9a9a;
}

.padderr{
  margin-bottom: 10em; 
  margin-top: 10em
}

@media screen and (min-width: 768px) {
.padderr{
  margin-bottom: 15em; 
  margin-top: 15em
}
  
}
</style>
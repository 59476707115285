<template>
  <div id="app">
    <Completed />
  </div>
</template>

<script>
import Completed from './citi/Completed'

export default {
  name: 'App',
  components: {
    Completed
  }
}
</script>

<style>
body{
  margin: 0 !important;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
